import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpEvent, HttpHeaders } from '@angular/common/http';

import {switchMap} from 'rxjs/operators';
import {MediaListResponse} from '../models/media/media-list-response';

import {Media} from '../models/media/media';
import {BaseClient} from './base-client.service';
import { Content } from '../models/media/content';
import { FileRename } from '../models/media/file-rename';
import { SignedUrl } from '../models/media/signed-url';

@Injectable()
export class MediaClient extends BaseClient {

  public getBaseEndpoint() {
    return this.baseEndpoint + '/media';
  }

  public getTopStatsEndpoint() {
    return this.baseEndpoint + '/getTopStats';
  }

  public list(): Observable<MediaListResponse> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<MediaListResponse>(this.getBaseEndpoint(),
        {headers: this.getHeaders(token)});
    }));
  }

  public show(id: string): Observable<Media> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Media>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public getPublishedMedia(): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<any>(this.getBaseEndpoint() + '/getComboDetails?status=published',{},
      {headers: this.getHeaders(token, false)});
    }));
  }

  


  public getSignedUrl(formData: FormData): Observable<SignedUrl> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<SignedUrl>(this.getBaseEndpoint()+"/signedURL", formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public store(formData: FormData): Observable<Media> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Media>(this.getBaseEndpoint(), formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public renameFile(formData: FormData): Observable<FileRename> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<FileRename>(this.getBaseEndpoint()+"/renameAudio", formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public reorderFiles(formData: FormData): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<any>(this.getBaseEndpoint()+"/reorder", formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public upload(formData: FormData): Observable<Content> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Content>(this.getBaseEndpoint()+'/addSingleAudio', formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public uploadV2(formData: FormData): Observable<HttpEvent<Content>>{
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Content>(this.getBaseEndpoint()+'/addSingleAudio', formData,
        {headers: this.getHeaders(token, false), reportProgress: true,
          observe: 'events'});
    }));
  }

  public update(id, formData: FormData): Observable<Media> {
    formData.append('_method', 'PUT');
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Media>(this.getBaseEndpoint() +  '/' + id, formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public delete(id): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.delete<any>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public deleteAudioFile(id): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<any>(this.getBaseEndpoint() + '/deleteAudio/' + id,
        {headers: this.getHeaders(token)});
    }));
  }
}
