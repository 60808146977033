import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';

import {switchMap} from 'rxjs/operators';
import {MediaListResponse} from '../models/media/media-list-response';
import {BaseClient} from './base-client.service';
import { Plans } from '../models/payment-plans/plans';
import { Organization } from '../models/organizations/organization';
import { SignedUrl } from '../models/media/signed-url';

@Injectable()
export class OrganizationClient extends BaseClient {

  public getBaseEndpoint() {
    return this.baseEndpoint + '/org';
  }

  public getBaseEndpointV2() {
    return this.baseEndpoint + '/media';
  }

  public list(): Observable<Array<Organization>> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Array<Organization>>(this.getBaseEndpoint(),
        {headers: this.getHeaders(token)});
    }));
  }

  public show(id: string): Observable<Organization> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Organization>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public store(formData: FormData): Observable<Organization> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Organization>(this.getBaseEndpoint(), formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public update(id, formData: FormData): Observable<Organization> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.put<Organization>(this.getBaseEndpoint() + '/' + id, formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public getSignedUrl(formData: FormData): Observable<SignedUrl> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<SignedUrl>(this.getBaseEndpointV2()+"/signedURL", formData,
        {headers: this.getHeaders(token, false)});
    }));
  }
}
